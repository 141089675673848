<template>
	<div class="score-evolution-card shadow-blue-not-openned pa-4 mb-6">
		<div class="d-flex flex-column flex-sm-row align-start align-sm-center mb-2">
			<div class="headline-chart pa-0 mb-2 mb-sm-0">Évolution des scores</div>
			<v-spacer></v-spacer>
			<v-select
				v-if="topics.length > 0"
				v-model="selectedTopic"
				:items="topicItems"
				dense
				outlined
				hide-details
				class="topic-selector ml-0 ml-sm-4"
				style="max-width: 200px; min-width: 150px"
				@change="filterByTopic"
			></v-select>
		</div>
		<div class="chart-content">
			<div v-if="isLoading" class="text-center pa-5">
				<v-progress-circular indeterminate color="primary"></v-progress-circular>
			</div>
			<div v-else-if="noData" class="text-center grey--text pa-5">
				Pas encore assez de données pour afficher la courbe d'évolution.
			</div>
			<div v-else class="chart-container" :style="{ height: isMobile ? '250px' : '350px' }">
				<apexchart
					:key="'chart-' + selectedTopic"
					type="area"
					height="100%"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'TestScoreTimeline',
	props: {
		userId: {
			type: String,
			required: true,
		},
		initialTopicSlug: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: true,
			noData: false,
			selectedTopic: null,
			topicName: '',
			testBanks: [], // Toutes les banques de tests
			isMobile: false, // Détection d'écran mobile
			windowWidth: 0, // Largeur de la fenêtre
			series: [
				{
					name: 'Score',
					data: [],
				},
			],
			chartOptions: {
				chart: {
					id: 'score-timeline',
					type: 'area',
					height: 350,
					toolbar: {
						show: false,
					},
					zoom: {
						enabled: false,
					},
					locales: [
						{
							name: 'fr',
							options: {
								months: [
									'Janvier',
									'Février',
									'Mars',
									'Avril',
									'Mai',
									'Juin',
									'Juillet',
									'Août',
									'Septembre',
									'Octobre',
									'Novembre',
									'Décembre',
								],
								shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
								days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
								shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
								toolbar: {
									download: 'Télécharger',
									selection: 'Sélection',
									selectionZoom: 'Sélection zoom',
									zoomIn: 'Zoom avant',
									zoomOut: 'Zoom arrière',
									pan: 'Panoramique',
									reset: 'Réinitialiser',
								},
							},
						},
					],
					defaultLocale: 'fr',
				},
				colors: ['#6b4de6'],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: 'smooth',
					width: 2,
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.7,
						opacityTo: 0.3,
						stops: [0, 100],
					},
				},
				markers: {
					size: 5,
					colors: ['#6b4de6'],
					strokeWidth: 0,
					hover: {
						size: 7,
					},
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: (val) => `${val}%`,
					},
					x: {
						format: 'dd MMMM yyyy',
					},
				},
				xaxis: {
					type: 'datetime',
					labels: {
						format: 'dd MMM',
						datetimeUTC: false, // Utiliser l'heure locale plutôt que UTC
					},
					tooltip: {
						enabled: true,
					},
				},
				yaxis: {
					min: 0,
					max: 100,
					title: {
						text: '', // Retrait du titre 'Score (%)'
						style: {
							fontSize: '12px',
							fontWeight: 500,
						},
					},
					labels: {
						formatter: (val) => `${val}%`,
					},
				},
				annotations: {
					points: [],
				},
			},
			topics: [],
			testScores: [],
			allTests: [],
		};
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		topicItems() {
			// Démarrer avec l'option 'Tous les sujets'
			const items = [{ text: 'Tous', value: null }];

			// Tableau pour stocker les topics avec des tests complétés
			const topicsWithTests = [];

			// Si nous avons des tests et des scores
			if (this.allTests && this.allTests.length > 0 && this.testScores && this.testScores.length > 0) {
				// Récupérer les IDs des banques de tests qui ont des scores
				const testIdsWithScores = this.testScores.map((score) => score.test_id);

				// Filtrer les tests qui ont des scores
				const testsWithScores = this.allTests.filter((test) => testIdsWithScores.includes(test._id));

				// Récupérer les IDs des banques de test pour ces tests
				const testBankIdsWithScores = testsWithScores.map((test) => test.test_bank_id);

				// Filtrer les topics qui ont des tests avec des scores
				this.topics.forEach((topic) => {
					// Vérifier si ce topic a au moins un test complété avec un score
					const topicHasCompletedTests = testBankIdsWithScores.some((testBankId) => {
						// Trouver la banque de test correspondante
						const testBank = this.testBanks.find((tb) => tb._id === testBankId);
						// Vérifier si cette banque appartient au topic en cours
						return testBank && testBank.topic_id === topic._id;
					});

					// Ajouter ce topic uniquement s'il a des tests complétés
					if (topicHasCompletedTests) {
						topicsWithTests.push({
							text: topic.name,
							value: topic.slug,
						});
					}
				});
			}

			// Concaténer avec 'Tous les sujets'
			return items.concat(topicsWithTests);
		},
	},
	async created() {
		this.selectedTopic = this.initialTopicSlug;
		this.checkScreenSize();
		window.addEventListener('resize', this.checkScreenSize);
		await this.loadData();
	},

	beforeDestroy() {
		// Nettoyer l'écouteur d'événement lors de la destruction du composant
		window.removeEventListener('resize', this.checkScreenSize);
	},
	methods: {
		async loadData() {
			try {
				this.isLoading = true;
				this.noData = false;

				// 1. Charger les topics si pas déjà fait
				if (this.topics.length === 0) {
					this.topics = await this.$store.dispatch('topics/fetchAllTopics');
				}

				// 2. Charger les tests de l'utilisateur (hors tests annulés)
				let tests = await this.$store.dispatch('tests/fetchAllUserTests', { userId: this.userId });
				this.allTests = tests.filter((test) => test.canceled_at == null);

				// 3. Charger les banques de tests pour identifier les topics
				const testBanks = await this.$store.dispatch('tests/searchTestBanks', {
					testBankIds: this.allTests.map((test) => test.test_bank_id),
				});
				// 4. Charger les scores des tests
				this.testScores = await this.$store.dispatch('testScores/fetchTestScores', { userId: this.userId });

				// Filtrer pour ne garder que les tests avec statut 'completed' ou autres statuts terminés
				if (this.testScores && this.testScores.length > 0) {
					// Filtrer les tests pour n'inclure que ceux dont le test est terminé
					// Nous acceptons 'completed' ou tout autre statut indiquant que le test est terminé
					const filteredScores = this.testScores.filter((score) => {
						// Filtrer directement sur le statut du score, pas du test
						const isCompleted = score.status === 'completed';

						return isCompleted;
					});

					// Si aucun résultat avec filtrage strict, utilisons tous les scores disponibles
					if (filteredScores.length > 0) {
						this.testScores = filteredScores;
					} else {
						// Mode de secours: utiliser tous les scores disponibles
					}
				}

				// 5. Préparer et filtrer les données
				this.prepareChartData(testBanks);
			} catch (error) {
				this.noData = true;
			} finally {
				this.isLoading = false;
			}
		},

		prepareChartData(testBanks) {
			// Stocker les banques de tests pour les utiliser dans le filtre des topics
			this.testBanks = testBanks;

			// CORRECTION: Vérifier si this.testScores existe et contient des données
			if (!this.testScores || this.testScores.length === 0) {
				this.noData = true;
				return;
			}

			let filteredScores = [...this.testScores];

			// Filtrer par topic si nécessaire
			if (this.selectedTopic) {
				const topic = this.topics.find((t) => t.slug === this.selectedTopic);
				if (topic) {
					this.topicName = topic.name;
					const testBanksOfTopic = testBanks.filter((tb) => tb.topic_id === topic._id);

					const testIdsOfTopic = this.allTests
						.filter((test) => testBanksOfTopic.some((tb) => tb._id === test.test_bank_id))
						.map((test) => test._id);

					filteredScores = filteredScores.filter((score) => testIdsOfTopic.includes(score.test_id));
				}
			} else {
				this.topicName = '';
			}

			// Trier par date
			filteredScores.sort((a, b) => new Date(a.last_answered_time) - new Date(b.last_answered_time));

			// Préparer les données pour le graphique
			if (filteredScores.length === 0) {
				this.noData = true;
				return;
			}

			// CORRECTION: Vérifier et utiliser le bon champ pour le score
			const dataPoints = filteredScores.map((score) => {
				// Vérifier différentes propriétés possibles pour trouver le score
				let scoreValue = 0;
				if (score.percentage_score !== undefined) {
					scoreValue = Math.round(score.percentage_score);
				} else if (score.score !== undefined) {
					scoreValue = Math.round(score.score);
				} else if (score.score_percentage !== undefined) {
					scoreValue = Math.round(score.score_percentage);
				}

				// Vérifier différentes propriétés de date
				let datePoint = null;
				if (score.last_answered_time) {
					datePoint = new Date(score.last_answered_time).getTime();
				} else if (score.completed_at) {
					datePoint = new Date(score.completed_at).getTime();
				} else if (score.created_at) {
					datePoint = new Date(score.created_at).getTime();
				}

				return {
					x: datePoint,
					y: scoreValue,
				};
			});

			// Filtrer les points sans date valide
			const validDataPoints = dataPoints.filter((point) => point.x !== null);

			if (validDataPoints.length === 0) {
				this.noData = true;
				return;
			}

			// Mettre à jour la série au sein d'un nextTick pour éviter les problèmes de rendu
			this.$nextTick(() => {
				this.series = [
					{
						name: 'Score',
						data: validDataPoints,
					},
				];
			});

			// Mettre en évidence le meilleur score
			const bestScore = [...dataPoints].sort((a, b) => b.y - a.y)[0];
			if (bestScore) {
				this.chartOptions = {
					...this.chartOptions,
					annotations: {
						points: [
							{
								x: bestScore.x,
								y: bestScore.y,
								marker: {
									size: 8,
									fillColor: '#6b4de6',
									strokeColor: '#fff',
									radius: 2,
								},
								label: {
									borderColor: '#6b4de6',
									offsetY: 0,
									style: {
										color: '#fff',
										background: '#6b4de6',
									},
									text: `${bestScore.y}%`,
								},
							},
						],
					},
				};
			}
		},

		filterByTopic() {
			const topic = this.topics.find((t) => t.slug === this.selectedTopic);
			this.topicName = topic ? topic.name : '';

			// Recharger les données avec le nouveau filtre
			this.loadData();
		},

		// Vérifier la taille de l'écran pour la version mobile
		checkScreenSize() {
			this.windowWidth = window.innerWidth;
			this.isMobile = window.innerWidth < 600;

			// Ajuster la configuration du graphique en fonction de la taille d'écran
			if (this.chartOptions) {
				// Réduire la taille des étiquettes et les marges sur mobile
				if (this.isMobile) {
					this.chartOptions.xaxis.labels.style = { fontSize: '10px' };
					this.chartOptions.yaxis.labels.style = { fontSize: '10px' };
					this.chartOptions.markers.size = 3;
					// Simplifier le format de date sur mobile pour gagner de l'espace
					this.chartOptions.xaxis.labels.format = 'd MMM';
				} else {
					this.chartOptions.xaxis.labels.style = { fontSize: '12px' };
					this.chartOptions.yaxis.labels.style = { fontSize: '12px' };
					this.chartOptions.markers.size = 5;
					// Format de date standard pour desktop
					this.chartOptions.xaxis.labels.format = 'dd MMM';
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
.score-evolution-card {
	border-radius: 10px;
	background-color: #fff;

	.headline-chart {
		font-weight: 700;
		font-size: 20px;
		color: rgba(0, 0, 0, 0.54);

		@media (max-width: 599px) {
			font-size: 1.1rem;
		}
	}

	.subtitle-topic {
		font-weight: 500;
		color: #6b4de6;
		font-size: 0.95rem;
	}

	.topic-selector {
		flex-shrink: 0;
	}

	.chart-content {
		padding: 0px !important;
		margin-left: -15px;
	}

	// Ajustements responsives pour mobile
	@media (max-width: 599px) {
		padding: 12px !important;
		margin-bottom: 16px !important;

		.chart-content {
			padding: 8px 0;
		}

		.chart-container {
			margin-top: 12px;
		}
	}
}
</style>

<template>
	<v-container>
		<div class="test-home-page">
			<h2 v-if="showMessageForWhenNoFormation" class="hello">Bonjour {{ profileInfos.first_name }},</h2>
			<h2 v-else class="hello">Mes défis</h2>
			<div v-if="showMessageForWhenNoFormation" class="gray-box-text mt-3">
				Bienvenue dans ton espace personnel, où tu retrouveras l'ensemble de tes tests. Tu peux consulter tes résultats
				et revoir la correction de chaque question.
			</div>

			<!-- Ajout du composant de courbe d'évolution -->
			<TestScoreTimeline
				v-if="!showMessageForWhenNoFormation && this.profileInfos._id && hasCompletedTests"
				:userId="this.profileInfos._id"
				:initialTopicSlug="topicSlugQuery"
				class="mt-4"
			/>

			<UserTestsList
				v-if="this.profileInfos._id"
				class="mt-5"
				:userId="this.profileInfos._id"
				:topicSlugQuery="topicSlugQuery"
			/>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserTestsList from '@/components/user-tests-list/UserTestsList.vue';
import TestScoreTimeline from '@/components/progress-charts/TestScoreTimeline.vue';

/** Note:
 * Test Home page have query topicSlug in url to filter test by topic.
 * If no topicSlug is provided, all tests will be displayed.
 * topicSlug is provided into child component: TestCard for user after click see test result can go back to the right test home page.
 * */

export default {
	name: 'TestHomePage',
	components: { UserTestsList, TestScoreTimeline },
	data() {
		return {
			hasCompletedTests: false,
			showMessageForWhenNoFormation: false,
		};
	},
	computed: {
		...mapGetters('profile', { profileInfos: 'profileInfos' }),
		topicSlugQuery() {
			return this.$route.query.topicSlug; // null || string
		},
	},
	async mounted() {
		window.scrollTo(0, 0);
	},
	async created() {
		if (this.profileInfos.first_name == null) await this.$store.dispatch('profile/getUserProfile');

		const formations = await this.$store.dispatch('formation/getAllFormationsOfUser');
		if (formations.length === 0) this.showMessageForWhenNoFormation = true;

		// Vérifier si l'utilisateur a des tests complétés
		await this.checkCompletedTests();
	},

	methods: {
		async checkCompletedTests() {
			try {
				// Vérifier si l'utilisateur a un ID
				if (!this.profileInfos._id) return;

				// Récupérer les scores des tests de l'utilisateur
				const testScores = await this.$store.dispatch('testScores/fetchTestScores', { userId: this.profileInfos._id });

				// Si l'utilisateur a au moins un test complété avec un score, afficher le graphique
				this.hasCompletedTests = testScores && testScores.length > 0;
			} catch (error) {
				console.error('Erreur lors de la vérification des tests complétés:', error);
				this.hasCompletedTests = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.hello {
	font-size: 2.3rem;
}
.gray-box-text {
	padding: 10px 12px;
	border-radius: 4px;
	background-color: #e6e6e6;
}
.test-home-page {
	margin-bottom: 50px;
}
</style>
